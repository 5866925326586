@font-face {
  font-family: "myriad-pro";
  src: url("../fonts/MyriadPro-Regular.eot?") format("eot"), url("../fonts/MyriadPro-Regular.woff2") format("woff2"), url("../fonts/MyriadPro-Regular.woff") format("woff"), url("../fonts/MyriadPro-Regular.ttf") format("truetype"), url("../fonts/MyriadPro-Regular.svg#myriad-pro") format("svg"); }

@font-face {
  font-family: "neo-sans-pro";
  src: url("../fonts/NeoSansPro-Medium.eot?") format("eot"), url("../fonts/NeoSansPro-Medium.woff2") format("woff2"), url("../fonts/NeoSansPro-Medium.woff") format("woff"), url("../fonts/NeoSansPro-Medium.ttf") format("truetype"), url("../fonts/NeoSansPro-Medium.svg#neo-sans-pro") format("svg"); }

html, body {
  margin: 0 auto;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "myriad-pro", Arial, sans-serif;
  font-weight: 300;
  background: #f0f2f5;
  font-size: 16px; }

section.app {
  margin: 0 auto;
  width: 100%;
  max-width: 1010px;
  height: 750px;
  position: relative;
  color: #ffffff;
  overflow: hidden; }
  @media screen and (max-width: 760px) {
    section.app {
      height: 1030px; } }
  section.app .lower {
    width: 100%;
    position: absolute;
    background: transparent url(../img/bg_light.png) center -224px no-repeat;
    height: 747px; }
    section.app .lower .question {
      position: absolute;
      display: inline-block;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 300px;
      background: transparent url(../img/q_bg.png) center 0 no-repeat;
      width: 551px;
      height: 106px;
      text-align: center;
      line-height: 106px; }
      section.app .lower .question:before, section.app .lower .question:after {
        background: transparent url(../img/q_wing_left.png);
        width: 36px;
        height: 80px;
        content: '';
        top: 12px;
        left: -6px;
        display: block;
        position: absolute;
        -webkit-transition: all .25s ease-in;
        -moz-transition: all .25s ease-in;
        -o-transition: all .25s ease-in;
        transition: all .25s ease-in; }
      section.app .lower .question:after {
        left: auto;
        right: -6px;
        background-image: url(../img/q_wing_right.png); }
      section.app .lower .question.changing:before {
        left: -20px; }
      section.app .lower .question.changing:after {
        right: -20px; }
      section.app .lower .question .q {
        vertical-align: middle;
        font-size: 18px;
        line-height: 22px;
        display: inline-block;
        width: 500px;
        -webkit-transition: all .25s ease-in;
        -moz-transition: all .25s ease-in;
        -o-transition: all .25s ease-in;
        transition: all .25s ease-in; }
        section.app .lower .question .q.hidden {
          display: none; }
      @media screen and (max-width: 760px) {
        section.app .lower .question {
          background: transparent url(../img/q_bg_mob.png) center 0 no-repeat;
          width: 100%;
          -moz-background-size: contain;
          background-size: contain;
          max-width: 371px;
          height: 176px;
          line-height: 176px; }
          section.app .lower .question:before, section.app .lower .question:after {
            display: none; }
          section.app .lower .question .q {
            width: 65%; } }
      @media screen and (max-width: 360px) {
        section.app .lower .question {
          line-height: 150px; }
          section.app .lower .question .q {
            font-size: 16px;
            line-height: 18px; } }
    section.app .lower .answers {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 450px;
      width: 100%;
      max-width: 450px; }
      section.app .lower .answers .block {
        display: block;
        width: 100%;
        -webkit-transition: all .25s ease-in;
        -moz-transition: all .25s ease-in;
        -o-transition: all .25s ease-in;
        transition: all .25s ease-in; }
        section.app .lower .answers .block.hidden {
          display: none; }
        section.app .lower .answers .block .answer {
          display: block;
          width: 50%;
          float: left;
          height: 80px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding-left: 40px;
          position: relative;
          color: #231F20;
          font-size: 18px;
          text-decoration: none; }
          section.app .lower .answers .block .answer:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent url(../img/checkboxes.png) 0 0 no-repeat;
            width: 24px;
            height: 27px; }
          section.app .lower .answers .block .answer:hover, section.app .lower .answers .block .answer .active {
            color: #FCEE23; }
            section.app .lower .answers .block .answer:hover:before, section.app .lower .answers .block .answer .active:before {
              background-position: right 0;
              width: 25px; }
      @media screen and (max-width: 760px) {
        section.app .lower .answers {
          max-width: 230px;
          top: 500px; }
          section.app .lower .answers .block .answer {
            width: 100%; } }
    section.app .lower .bullets {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      width: 100%;
      max-width: 400px;
      top: 635px; }
      section.app .lower .bullets .bullet {
        display: inline-block;
        width: 8px;
        height: 8px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background: #A5CD39;
        margin: 0 6px;
        -webkit-transition: all .25s ease-in;
        -moz-transition: all .25s ease-in;
        -o-transition: all .25s ease-in;
        transition: all .25s ease-in; }
        section.app .lower .bullets .bullet.active {
          background: #FCEE23; }
      @media screen and (max-width: 760px) {
        section.app .lower .bullets {
          top: 850px; } }
    @media screen and (max-width: 760px) {
      section.app .lower {
        height: 980px;
        background-position: center 0; } }
  section.app .upper {
    width: 100%;
    position: absolute;
    background: transparent url(../img/bg_dark.png) center -246px no-repeat;
    height: 747px;
    color: #ffffff;
    font-size: 26px;
    font-family: 'neo-sans-pro', 'myriad-pro', Arial, sans-serif;
    -webkit-transition: all .5s ease-in;
    -moz-transition: all .5s ease-in;
    -o-transition: all .5s ease-in;
    transition: all .5s ease-in;
    top: 0; }
    section.app .upper.up {
      top: -450px; }
    section.app .upper .carrot {
      position: absolute;
      top: 290px;
      width: 400px;
      left: 50%;
      margin-left: -245px; }
      @media screen and (max-width: 760px) {
        section.app .upper .carrot {
          text-align: center;
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 20px;
          left: 0;
          right: 0;
          margin: auto; } }
    section.app .upper .user {
      max-width: 370px;
      width: 90%;
      position: absolute;
      top: 290px;
      margin: 0 auto;
      left: 0;
      right: 0;
      z-index: 1;
      display: none; }
      section.app .upper .user input[type=text] {
        width: 100%;
        border: none;
        font-size: 18px;
        color: #231F20;
        font-family: 'myriad-pro', Arial, sans-serif;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 8px 15px;
        margin-bottom: 8px;
        background: #ffffff; }
        section.app .upper .user input[type=text].short {
          width: 48%; }
          section.app .upper .user input[type=text].short#name {
            margin-right: 4%; }
        section.app .upper .user input[type=text].error {
          background: #ED1C24; }
      section.app .upper .user .register-btn {
        display: block;
        text-decoration: none;
        outline: none;
        background: transparent url(../img/register.png);
        width: 273px;
        height: 71px;
        color: #ffffff;
        font-size: 24px;
        text-align: center;
        font-family: 'neo-sans-pro', 'myriad-pro', Arial, sans-serif;
        padding-top: 19px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        text-shadow: #907136 0px 3px 0px;
        margin: 20px auto 0; }
      section.app .upper .user input[type=checkbox] {
        display: none; }
        section.app .upper .user input[type=checkbox] + label {
          display: block;
          vertical-align: middle;
          font-size: 16px;
          font-family: "myriad-pro", Arial, sans-serif;
          color: #ffffff;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          margin: 20px auto 0;
          width: 210px;
          padding-left: 40px;
          position: relative; }
          section.app .upper .user input[type=checkbox] + label:before {
            content: '';
            display: block;
            position: absolute;
            top: 12px;
            left: 0;
            width: 20px;
            height: 20px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background: #ffffff; }
          section.app .upper .user input[type=checkbox] + label:after {
            content: '';
            display: block;
            position: absolute;
            top: 7px;
            left: 0;
            width: 24px;
            height: 25px;
            -webkit-transition: all .25s ease-in;
            -moz-transition: all .25s ease-in;
            -o-transition: all .25s ease-in;
            transition: all .25s ease-in; }
        section.app .upper .user input[type=checkbox]:checked + label:after {
          background: transparent url(../img/check.png); }
      @media screen and (max-width: 760px) {
        section.app .upper .user {
          top: 540px; } }
    section.app .upper .fail {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      max-width: 600px;
      top: 260px;
      text-align: center;
      display: none; }
      section.app .upper .fail .txt {
        margin: auto;
        max-width: 450px;
        width: 100%; }
      section.app .upper .fail .res {
        font-family: 'myriad-pro', Arial, sans-serif;
        font-weight: normal;
        font-size: 18px; }
      section.app .upper .fail .bullet {
        display: inline-block;
        width: 24px;
        height: 24px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        background: #A5CD39;
        margin: 35px 17px 40px;
        -webkit-transition: all .25s ease-in;
        -moz-transition: all .25s ease-in;
        -o-transition: all .25s ease-in;
        transition: all .25s ease-in; }
        section.app .upper .fail .bullet.wrong {
          background: #ED1C24; }
      section.app .upper .fail .legend span {
        display: inline-block; }
        section.app .upper .fail .legend span:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 12px;
          height: 12px;
          background: #A5CD39;
          margin: -3px 18px 0 25px;
          -moz-border-radius: 6px;
          border-radius: 6px; }
        section.app .upper .fail .legend span:first-of-type:after {
          background: #ED1C24; }
      @media screen and (max-width: 760px) {
        section.app .upper .fail {
          top: 540px;
          padding: 0 20px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box; }
          section.app .upper .fail .bullet {
            margin: 35px 5px 40px;
            width: 16px;
            height: 16px; } }
    section.app .upper .done {
      display: none; }
      section.app .upper .done .txt {
        position: absolute;
        top: 270px;
        left: 50%;
        margin-left: -290px; }
        section.app .upper .done .txt img {
          max-width: 90%; }
      @media screen and (max-width: 760px) {
        section.app .upper .done .txt {
          left: 0;
          right: 0;
          margin: auto;
          width: 90%;
          text-align: center;
          top: 235px; } }
      @media screen and (max-width: 440px) {
        section.app .upper .done {
          font-size: 20px; } }
    @media screen and (max-width: 760px) {
      section.app .upper {
        background-position: center 0;
        height: 980px; }
        section.app .upper.up {
          top: -700px; } }
  section.app .logo {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    width: 251px;
    margin: 0 auto;
    -webkit-transition: all .5s ease-in;
    -moz-transition: all .5s ease-in;
    -o-transition: all .5s ease-in;
    transition: all .5s ease-in; }
    section.app .logo img {
      display: block;
      width: 100%; }
    section.app .logo.small {
      width: 200px; }
  section.app .mobile {
    position: absolute;
    background: transparent url(../img/phone.png) 0 0 no-repeat;
    width: 187px;
    height: 353px;
    left: 50%;
    margin-left: 120px;
    top: 110px;
    -webkit-transition: all .25s ease-in;
    -moz-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in; }
    section.app .mobile .st {
      position: absolute;
      background: transparent url(../img/18.png);
      width: 80px;
      height: 43px;
      right: -54px;
      top: 260px; }
    section.app .mobile .slogan {
      position: absolute;
      background: transparent url(../img/cat-model.png);
      width: 100px;
      height: 20px;
      opacity: 0;
      top: 178px;
      left: 125px; }
    section.app .mobile.sm {
      width: 195px;
      top: 20px;
      margin-left: 180px;
      background-image: url(../img/cat-sm.png); }
      section.app .mobile.sm .st {
        right: 20px;
        top: 140px;
        background: transparent url(../img/18-2m.png);
        width: 49px;
        height: 31px; }
      section.app .mobile.sm .slogan {
        opacity: 1; }
    @media screen and (max-width: 760px) {
      section.app .mobile {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 430px;
        background-position: 0 -45px;
        height: 308px; }
        section.app .mobile .st {
          top: 230px; }
        section.app .mobile.sm {
          top: 280px;
          left: 0;
          right: 0;
          margin: 0 auto;
          background-position: 0 0;
          height: 240px; }
          section.app .mobile.sm.registered {
            top: 50px;
            width: 130px;
            right: auto;
            left: 50%;
            margin-left: 80px; }
            section.app .mobile.sm.registered .st {
              right: 48px;
              top: 163px; }
            section.app .mobile.sm.registered .slogan {
              left: 0;
              top: 200px; } }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #231F20; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #231F20; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #231F20; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #231F20; }

.btn {
  display: block;
  position: absolute;
  max-width: 416px;
  left: 0;
  margin: 0 auto;
  top: 465px;
  width: 80%;
  right: 0; }
  .btn img {
    display: block;
    width: 100%; }
    .btn img.m {
      display: none; }
  .btn:before {
    content: '';
    display: block;
    position: absolute;
    background: transparent url(../img/wing_left.png);
    width: 25px;
    height: 54px;
    left: 5px;
    top: 25px;
    -webkit-transition: all .25s ease-in;
    -moz-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in; }
  .btn:after {
    content: '';
    display: block;
    position: absolute;
    background: transparent url(../img/wing_right.png);
    width: 25px;
    height: 54px;
    right: 5px;
    top: 25px;
    -webkit-transition: all .15s ease-in;
    -moz-transition: all .15s ease-in;
    -o-transition: all .15s ease-in;
    transition: all .15s ease-in; }
  .btn:hover:before {
    left: 15px; }
  .btn:hover:after {
    right: 15px; }
  @media screen and (max-width: 760px) {
    .btn {
      top: 750px;
      max-width: 332px; }
      .btn:before, .btn:after {
        display: none; }
      .btn img.main {
        display: none; }
      .btn img.m {
        display: block; } }
  .btn.repeat {
    position: relative;
    top: 0; }

.popup {
  position: fixed;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  overflow: auto;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px 20px 20px;
  font-size: 16px;
  color: #231F20;
  background: #ffffff;
  display: none;
  z-index: 500; }
  .popup .close {
    display: block;
    text-decoration: none;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 20px;
    font-weight: bold;
    color: #231F20; }
    .popup .close:hover {
      color: #ED1C24; }

footer {
  margin: 0 auto;
  max-width: 1010px;
  width: 100%;
  position: relative; }

.bottom-btn {
  color: #ffffff;
  font-size: 15px;
  text-decoration: none;
  background: #9E9B97;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 5px 20px;
  position: absolute;
  top: 700px;
  left: 30px;
  -webkit-box-shadow: #7B7876 0 4px 0 0;
  -moz-box-shadow: #7B7876 0 4px 0 0;
  box-shadow: #7B7876 0 4px 0 0; }
  .bottom-btn:hover {
    -webkit-box-shadow: #7B7876 0 2px 0 0;
    -moz-box-shadow: #7B7876 0 2px 0 0;
    box-shadow: #7B7876 0 2px 0 0;
    top: 702px; }
  .bottom-btn.winners {
    left: auto;
    right: 30px; }
  @media screen and (max-width: 760px) {
    .bottom-btn {
      top: 980px; }
      .bottom-btn:hover {
        top: 982px; } }

.clearfix {
  clear: both; }

.admin-nav {
  position: absolute;
  z-index: 10;
  background: #ffffff;
  padding: 5px 10px; }
  .admin-nav a {
    color: #231F20;
    font-weight: bold;
    text-decoration: none; }
    .admin-nav a:hover {
      text-decoration: underline; }

/*# sourceMappingURL=app.css.map */
